<template>
    <template
        v-if="!(!configurableFieldExists && hideByDefault) || configurableFieldExists && configurableFieldIsVisible(sharedObj.visibleFieldsObject, fieldName) || forceVisibility">
        <template v-if="showItem && !props.groupHeader && !props.useFullRowForValue">
            <template v-if="isMobile">
                <div :title="props.title" class="d-flex flex-column justify-content-between bg-white">
                    <div class="ps-2 text-muted">
                        <span :title="props.title">{{ props.caption }} {{ unit ? `(${unit})` : "" }}</span>
                        <template
                            v-if="sharedObj.visibleFieldsObject && fieldName && configurableFieldIsMandatory(sharedObj.visibleFieldsObject, fieldName)">*</template>
                        <template v-if="isRequired">*</template>
                    </div>
                    <div class="px-1 _properties-item-mobile"
                        :class="{ 'required-prop': isRequired, '_properties-item-dirty': showDirty }" style="z-index: 2;"
                        ref="propertiesItem">
                        <slot>
                            <template v-if="isBoolean()">
                                <input class="form-check-input fs-6 m-0 mt-1 ms-1" :title="props.title ?? props.caption"
                                    type="checkbox" v-model='dataObject.current[props.fieldName]'>
                            </template>
                            <template v-if="isDateTime()">
                                <ODatePicker class="form-control" :title="props.title"
                                    v-model='dataObject.current[props.fieldName]' show-input-icon date
                                    format="Short Date" />
                            </template>
                            <template v-if="isString()">
                                <input class="form-control" v-model="dataObject.current[props.fieldName]" />
                            </template>
                        </slot>
                    </div>
                    <OActionSaveCancel justify="end" style="margin-top: 0.75rem;" v-model="showSaveButton"
                        :row="dataObject.current" :field="fieldName" />
                </div>
            </template>
            <template  v-else>
                <div :title="props.title" class="d-flex justify-content-between border-bottom bg-white property-item"
                    @click="setActive($event)" @keyup="setActive($event)" :class="{ 'active-field': active }">
                    <span style="width:40%;" class="ps-2 pe-2 d-flex text-muted">
                        <div class="flex-shrink-0 me-1">
                            <i v-if="dataObject?.current.isSaving && showDirty&&!props.disabled"
                                class="spinner-border spinner-border-sm mt-1" style="cursor: pointer;"
                                :title='$t("Saving...")'></i>
                            <i v-else class="bi bi-check-lg icon-bold text-primary" style="cursor: pointer;"
                                :disabled="true"
                                :title='$t("Save")' @click="dataObject.current.save()"
                                :class="{ 'property-item-dirty-state': !showDirty }"></i>
                        </div>
                        <div class="flex-grow-1 border-end">
                            <span :title="props.title">{{ props.caption }}  </span>
                            <template
                                v-if="sharedObj.visibleFieldsObject && fieldName && configurableFieldIsMandatory(sharedObj.visibleFieldsObject, fieldName)">*</template>
                            <template v-if="isRequired">*</template>
                        </div>
                    </span>
                    <span
                        class="_properties-item d-flex" 
                        :class="{ 'required-prop': isRequired, disabled: props.disabled, 'is-dev-test': isDevTest }"
                        style="width:60%;"
                    >
                        <div class="flex-1 d-flex">
                            <slot>
                                <template v-if="isBoolean()">
                                    <input class="form-check-input fs-6 m-0 mt-1 ms-1" :title="props.title ?? props.caption"
                                        type="checkbox" v-model='dataObject.current[props.fieldName]'>
                                </template>
                                <template v-if="isDateTime()">
                                    <ODatePicker
                                        class="form-control"
                                        :title="props.title"
                                        v-model='dataObject.current[props.fieldName]' show-input-icon date
                                        format="Short Date" />
                                </template>
                                <template v-if="isString()">
                                    <input  :disabled="props.disabled" class="form-control" v-model="dataObject.current[props.fieldName]" />
                                </template>
                            </slot>
                        </div>
                        <template v-if="unit">
                            <div class="flex-2">
                                <div class="ms-2 text-muted">{{ unit }}</div>
                            </div>
                        </template>
                        <div class="flex-shrink-0">
                            <i class="bi bi-arrow-counterclockwise me-2 btn btn-link p-0"
                                :class="{ 'property-item-dirty-state': !showDirty }" @click="resetValue()"
                                title="Cancel pending changes"></i>
                        </div>
                    </span>
                </div>
            </template>
        </template>
    </template>
</template>

<script setup lang="ts">
import { ref, inject, watch, computed } from 'vue';
import { InjectionKeys } from 'o365-utils';
import { configurableFieldIsVisible, configurableFieldIsMandatory } from "./PropertiesGrid.PropertiesItem.ConfigurableFields.ts";
import { ODatePicker } from 'o365-ui-components';
import { isMobile, isDevTest } from "o365.GlobalState.ts";
import OActionSaveCancel from "./Actions.SaveCancel.vue";

const props = defineProps({
    caption: String,
    groupHeader: String,
    useFullRowForValue: Boolean,
    fieldName: String,
    forceVisibility: Boolean,
    title: String,
    required: Boolean,
    disabled: {
        type: Boolean,
        default: false,
    },
    hideByDefault: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: ""
    }
});

const sharedObj = inject(InjectionKeys.propertiesGridKey, null);
const dataObject = sharedObj.value.dataObject;
// dataObject.allowUpdate=!props.disabled;
const show = ref(true);
const active = ref(false);
const showSaveButton = ref(false); // SaveCancel component will turn this off automatically

const unit = computed<string>(() => dataObject?.propertiesData?.propertiesDefinitions?.[props.fieldName]?.unit);


const showDirty = computed(() => {
    // field changed
    if (sharedObj?.value?.dataObject?.current?.changes?.[props.fieldName] !== undefined) {
        return true;
    }
    // property changed
    if (sharedObj?.value?.dataObject?.current?.propertiesRows?.[props.fieldName]?.hasChanges) {
        return true;
    }
    return false;
});

watch(showDirty, () => {
    if (showDirty.value) {
        showSaveButton.value = true;
    }
});

const configurableFieldExists = computed(() => {
    if (sharedObj.value.visibleFieldsObject) {
        const index = sharedObj.value.visibleFieldsObject.data.findIndex(f => f.FieldName === props.fieldName);
        return index !== -1;
    } else {
        return true;
    }
});

const showItem = computed(() => {
    if (configurableFieldExists.value && configurableFieldIsMandatory(sharedObj.value.visibleFieldsObject, props.fieldName)) {
        return true; // If the Item is a configurable field and is required, we always show it.
    } else if (isRequired.value) {
        return true; // if the Item is required, we always show it
    } else if (sharedObj.value.withValues === true && !dataObject.current[props.fieldName]) { // sharedObj.value.withValues ("hide blanks") on true hides content
        return false;
    } else if (show.value && isEmpty.value) {
        return false;
    } else if (show.value && !isEmpty.value) {
        return true;
    } else if (!show.value) {
        return false;
    } else {
        return true;
    }
});

async function setActive(e) {
    if (active.value === true) {
        return;
    }
    active.value = true;
    sharedObj.value.activeItemSwitch = props.fieldName;
    if (dataObject.current.changes !== null&& !props.disabled) {
        
        dataObject.current.save();
    }
}

function resetValue() {
    dataObject.current[props.fieldName] = dataObject.current.oldValues[props.fieldName];
}

watch(() => sharedObj.value.activeItemSwitch, (newVal) => {
    if (newVal != props.fieldName) {
        active.value = false;
    }
});

watch(() => sharedObj.value.propFilter, (newVal) => {
    if (isRequired.value) {
        show.value = true;
        return;
    }
    if (!newVal) {
        show.value = true;
    }
    show.value = props.caption && props.caption.toLowerCase().includes(newVal.toLowerCase())
});

const isEmpty = ref(false);
watch(() => sharedObj.value.withValues, (newVal) => {
    if (newVal && dataObject && props.fieldName) {
        if (!dataObject.current[props.fieldName] && show.value) {
            isEmpty.value = true;
        } else {
            isEmpty.value = false;
        }
    } else {
        isEmpty.value = false;
    }
});

const isRequired = computed(() => {
    return dataObject.getFields(props.fieldName) && (dataObject.getFields(props.fieldName).nullable === false && !isBoolean) || props.required === true;
});

function isBoolean() {
    return dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName).dataType === 'bit';
};

function isDateTime() {
    return dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName)?.dataType === 'datetime' || dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName)?.dataType === 'date' || dataObject.getFields(props.fieldName)?.dataType === 'datetime2';
};

function isString() {
    return dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName)?.dataType === "nvarchar";
}
</script>

<style>
.required-prop {
    background-color: cornsilk;
}

.active-field {
    outline: solid cornflowerblue !important;
    outline-width: 1px !important;
}

.previous-property-element {
    border-bottom: 0px !important;
}

.property-item {
    margin-bottom: 1px;
}

.property-item-dirty-state {
    visibility: hidden;
}

.required-prop>textarea {
    background-color: inherit;
}

/* remove form-control styling on desktop (much easier than applying it only for mobile) */
._properties-item .form-control {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-right: 2.33rem;
    border-radius: 0.25rem;
    border: none;
}

._properties-item .form-control:focus {
    box-shadow: inset 0px 0px 0px 2px rgba(0%, 0%, 0%, 100%);
}

/* mobile dirty highlight */
._properties-item-mobile._properties-item-dirty .form-control {
    border-color: cornflowerblue;
}

/* TODO: REMOVE */
/* temporary fix while apps still don't have form-control in PropertiesGrid */
._properties-item>input:not([type="checkbox"]) {
    width: 100%;
    border: none;
}

._properties-item>select {
    width: 100%;
    border: none;
}

._properties-item>input {
    outline: none !important;
}

._properties-item input.w-100, ._properties-item input.border-0 {
    /* please change from w-100 border-0 to form-control and it will work as you expect */
    /* background-color: purple !important; */
}





/* very annoying style to force people to fix their properties inputs */
/* (only shows on dev-test) */

._properties-item.is-dev-test:has(input.w-100):after,
._properties-item.is-dev-test:has(input.border-0):after,
._properties-item.is-dev-test:has(textarea.w-100):after,
._properties-item.is-dev-test:has(textarea.border-0):after
{
    content: "(dev-test only) remove w-100 border-0, add form-control";
    color: white !important;
    background: purple !important;
    padding: 0 0.5rem;
    border-radius: 1rem;
}





._properties-item *::placeholder {
    color: var(--bs-gray-500) !important;
}</style>

<style scoped>.slide-down-enter-from,
.slide-down-leave-to {
    max-height: 0px;
    transform: translate(0px, -40px);
    opacity: 0%;
}
.disabled
{
  pointer-events: none;
  opacity: 0.5;
}
.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.slide-down-enter-to,
.slide-down-leave-from {
    max-height: 100px;
    transform: translate(0px, 0px);
    opacity: 100%;
}</style>
